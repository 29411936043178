import { Section } from "./Section";
import React from "react";
import { Button } from "@components-v3/Button";
import { H2 } from "@util/standard";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import SlidingCarousel from "@components-v3/SlidingCarousel";
import { ContentWrapper } from "@components-v3/ContentWrapper";
import { GlowCard } from "@components-v3/GlowCard";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@util/constants";

interface CaseStudyItem {
  image: IGatsbyImageData;
  title: string;
  description: string;
}

interface CaseStudiesSectionProps {
  caseStudies: CaseStudyItem[];
  sectionTitle: string;
  sectionDescription: string;
  buttonUrl: string;
}

const CaseStudyCardRow = styled("div")`
  place-content: center;
  text-align: center,
  width: 300px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 240px;
  }
`;

const CaseStudiesSection = ({
  caseStudies,
  sectionTitle,
  sectionDescription,
  buttonUrl,
}: CaseStudiesSectionProps) => {
  return (
    <Section
      style={{
        textAlign: "center",
        alignItems: "center",
        gap: 0,
      }}
    >
      <ContentWrapper>
        <H2 style={{ fontFamily: "header bold", marginBottom: "10px" }}>
          {sectionTitle}
        </H2>
        <p style={{ width: "80%", marginBottom: "20px" }}>
          {sectionDescription}
        </p>
        <SlidingCarousel>
          {caseStudies.map(cs => {
            return (
              <div style={{ margin: "10px 5px", height: "100%" }}>
                <GlowCard flexDirection={"column"}>
                  <CaseStudyCardRow>
                    <GatsbyImage
                      image={cs.image}
                      style={{ width: "120px" }}
                      alt={cs.title}
                    ></GatsbyImage>
                  </CaseStudyCardRow>
                  <CaseStudyCardRow>
                    <h4 style={{ margin: 0 }}>{cs.title}</h4>
                  </CaseStudyCardRow>
                  <CaseStudyCardRow
                    style={{
                      padding: "0 10px 20px 10px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        overflowY: "hidden",
                        height: "160px",
                      }}
                    >
                      {cs.description}
                    </p>
                  </CaseStudyCardRow>
                </GlowCard>
              </div>
            );
          })}
        </SlidingCarousel>
        <div style={{ maxWidth: "200px", marginTop: "60px" }}>
          <Button url={buttonUrl} linkText={"See case studies"}></Button>
        </div>
      </ContentWrapper>
    </Section>
  );
};

export { CaseStudiesSection };
export type { CaseStudyItem, CaseStudiesSectionProps };
