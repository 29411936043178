import { Button } from "@components-v3/Button";
import { AutoScrollingCarousel } from "@components-v3/AutoScrollingCarousel";
import { Container } from "@util/standard";
import React from "react";
import { Section } from "./Section";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { ContentWrapper } from "@components-v3/ContentWrapper";

interface ScrollingLogoProps {
  title?: string;
  buttonText?: string;
  url?: string;
  logos: IGatsbyImageData[];
}

const ScrollingLogoSection = ({
  title,
  buttonText,
  url,
  logos,
}: ScrollingLogoProps) => {
  return (
      <Section style={{padding: "0px 0px 70px 0px"}}>
        <ContentWrapper style={{gap: '40px'}}>
          <Container
            display="flex"
            style={{ gap: "10px", justifyContent: "center", flexWrap: "wrap" }}
          >
            {!!title && (
              <div style={{ flexShrink: 1, placeContent: "center" }}>
                <h4 style={{ textAlign: "center", margin: 0 }}>{title}</h4>
              </div>
            )}
            {!!buttonText && (
              <div
                style={{
                  flexGrow: 1,
                  placeContent: "center",
                  maxWidth: "150px",
                }}
              >
                <Button linkText={buttonText} url={url}></Button>
              </div>
            )}
          </Container>
          <AutoScrollingCarousel
            itemWidth={200}
            itemHeight={50}
            items={logos.map((l, ind) => {
              return (
                <div
                  style={{
                    width: "200px",
                    height: "50px",
                    placeContent: "center",
                  }}
                >
                  <GatsbyImage
                    style={{ width: "200px", height: "50px" }}
                    image={l}
                    alt={`Logo Image ${ind}`}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              );
            })}
          />
        </ContentWrapper>
      </Section>
  );
};

export { ScrollingLogoSection };
export type { ScrollingLogoProps };
