import React from "react";
import { GlowCard } from "./GlowCard";
import { Maybe, Scalars } from "@graphql-types";
import BlocksContent from "@components/blocksContent";
import styled from "styled-components";
import { colors } from "@util/constants";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

interface ReviewCardProps {
  image: IGatsbyImageData;
  name: string;
  jobTitle: string;
  companyName: string;
  content: Maybe<Scalars["JSON"]>;
}

const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-wrap: pretty;
`;

const TitlesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  text-wrap: pretty;
`;

const ProfileImage = styled(GatsbyImage)`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  align-self: center;
`;

const StyledName = styled("p")`
  font-size: 20px;
  color: ${colors.black};
  ont-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 20px;
  letter-spacing: normal;
  text-align: left;
  margin: 0;

  @media screen and (max-width: 360px) {
    font-size: 18px;
  }
`;

const StyledTitles = styled("p")`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  height: 20px;
  overflow: hidden;
  text-align: left;
  color: ${colors.formTextGrey};
  margin: 0;

  @media screen and (max-width: 360px) {
    font-size: 12px;
  }
`;

const TextWrapper = styled("div")`
  height: 200px;
`;

const ReviewCard = ({
  image,
  name,
  jobTitle,
  companyName,
  content,
}: ReviewCardProps) => {
  return (
    <GlowCard flexDirection={"column"}>
      <HeaderContainer>
        <div style={{width: "80px"}}>
          <ProfileImage
            image={image}
            alt="Review Profile Image"
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <TitlesContainer>
          <StyledName>{name}</StyledName>
          <StyledTitles>{jobTitle}</StyledTitles>
          <StyledTitles>{companyName}</StyledTitles>
        </TitlesContainer>
      </HeaderContainer>
      <TextWrapper>
        <BlocksContent blocks={content}></BlocksContent>
      </TextWrapper>
    </GlowCard>
  );
};

export { ReviewCard };
export type { ReviewCardProps };
