import { Carousel } from "antd";
import React, { useRef } from "react";
import styled from "styled-components";

import { MOBILE_BREAKPOINT, colors, icons } from "@util/constants";
import { Container } from "@util/standard";
import { CarouselProps, CarouselRef } from "antd/lib/carousel";
import { useSafeMediaQuery } from "@util/hooks";

interface SlidingCarouselProps {
  children: JSX.Element[];
}

const CarouselContainer = styled(Container)`
  .ant-carousel {
    display: flex;
    place-content: center;
  }
  
  .ant-carousel .slick-initialized .slick-slide {
    display: flex;
    place-content: center;
  }
`;

const StyledCarousel = styled(Carousel)`
  width: 90%;
  max-width: 1100px;

  .slick-dots {
    display: flex !important;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    bottom: -40px;

    list-style-type: none;

    li {
      margin: 0 0.25rem;

      button {
        display: block;
        width: 1rem;
        height: 1rem;
        padding: 0;

        border: none;
        border-radius: 100%;

        text-indent: -9999px;

        border: 2px solid ${colors.lightBlue};
        border-radius: 12px;
        background-color: ${colors.lightBlue};
      }
    }

    li.slick-active button {
    }
  }

  div {
    display: flex;
  }
`;

export const CarouselItem = styled("div")`
  display: flex;
  width: 100%;
  max-width: 350px;
  place-content: center;
  align-self: center;
  justify-self: center;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 330px;
  }
`;

const Arrow = styled.img<{
  right?: boolean;
}>`
  filter: brightness(0.5);
  display: block;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ right }) => (right ? "right: 0px;" : "left: 0px;")};
`;

function SlidingCarousel({ children }: SlidingCarouselProps) {
  const isMobile = useSafeMediaQuery({
    maxWidth: 999,
  });

  const isTablet = useSafeMediaQuery({
    maxWidth: 1500,
    minWidth: 999,
  });

  const numSlidesVisible = Math.min(
    isMobile ? 1 : isTablet ? 2 : 3,
    children.length
  );

  const carouselSettings: CarouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: numSlidesVisible,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    draggable: true,
    lazyLoad: "ondemand",
  };
  const carouselSlider = useRef<CarouselRef>(null);
  return (
    <CarouselContainer
      width="100%"
      mobileWidth="100%"
      tabletWidth="100%"
      position="relative"
    >
      <Container
        position="absolute"
        width="100%"
        height="100%"
        top="0px"
        style={{
          visibility:
            !isMobile && children.length > numSlidesVisible
              ? "visible"
              : "hidden",
        }}
      >
        <Arrow
          src={icons.leftArrow}
          alt={"Left Arrow"}
          onClick={() => carouselSlider?.current?.prev()}
          width={28}
          height={28}
        />
        <Arrow
          right
          src={icons.rightArrow}
          alt={"Right Arrow"}
          onClick={() => carouselSlider?.current?.next()}
          width={28}
          height={28}
        />
      </Container>
      <StyledCarousel {...carouselSettings} ref={carouselSlider}>
        {children.map((contentItem, ind) => (
          <CarouselItem key={`content-item-${ind}`}>{contentItem}</CarouselItem>
        ))}
      </StyledCarousel>
    </CarouselContainer>
  );
}
export default SlidingCarousel;
