import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Paper } from "./Paper";

const GlowPaper = styled(Paper)<{
  flexDirection: CSSProperties["flexDirection"];
}>`
  min-width: 280px;
  min-height: 200px;
  width: fit-content;
  display: flex;
  flex-direction: ${p => p.flexDirection};
  gap: 10px;
  background-color: #fff;
  border-radius: 20px;
  margin: 10px;
  box-shadow: 0 0 10px 10px rgba(73, 169, 252, 0.2);
  align-items: center;
`;

const GlowCardItem = styled("div")
`
  flex-grow: 1;
  width: 100%;
  place-content: center;
  text-align: center;
`

interface GlowCardProps {
  children: JSX.Element[];
  flexDirection: CSSProperties["flexDirection"];
}

const GlowCard = ({ children, flexDirection }: GlowCardProps): JSX.Element => {
  return (
    <GlowPaper flexDirection={flexDirection}>
      {children.map(c => {
        return <GlowCardItem>{c}</GlowCardItem>;
      })}
    </GlowPaper>
  );
};

export { GlowCard };
export type { GlowCardProps };
