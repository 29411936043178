import React from "react";
import styled, { keyframes } from "styled-components";

interface AutoscrollingCarouselProps {
  items: JSX.Element[];
  itemWidth: number;
  itemHeight: number;
}

const CarouselContainer = styled("div")<{
  numItems: number;
  itemWidth: number;
}>`
  display: flex;
  block-size: 100%;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  max-inline-size: ${props => props.itemWidth * (props.numItems - 1)}px;
  min-inline-size: 20vw;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const goFrame = (itemWidth: number, numItems: number) => keyframes`
  to {
      transform: translate(calc(-${itemWidth}px * (${numItems + 1})), 0);
  }
`;

const CarouselItem = styled("div")<{
  itemWidth: number;
  numItems: number;
  itemPos: number;
}>`
  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  animation: ${p => goFrame(p.itemWidth, p.numItems)} linear 40s var(--marquee-delay, 0s)
    infinite;
  --marquee-delay: calc(
    40s / ${p => p.numItems} * (${p => p.numItems} - ${p => p.itemPos}) * -1
  );
  --marquee-item-offset: max(
    calc(${p => p.itemWidth}px * ${p => p.numItems}),
    calc(100% + ${p => p.itemWidth}px)
  );
`;

const AutoScrollingCarousel = ({
  items,
  itemWidth,
  itemHeight,
}: AutoscrollingCarouselProps) => {
  return (
    <CarouselContainer
      itemWidth={itemWidth}
      numItems={items.length}
      style={{ height: itemHeight }}
    >
      {items.map((item, ind) => {
        return (
          <CarouselItem
            numItems={items.length}
            itemWidth={itemWidth}
            itemPos={ind}
            key={`auto-scrolling-item-${ind}`}
          >
            {item}
          </CarouselItem>
        );
      })}
    </CarouselContainer>
  );
};

export { AutoScrollingCarousel };
export type { AutoscrollingCarouselProps };
