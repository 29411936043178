import { Section } from "./Section";
import React from "react";
import { H2 } from "@util/standard";
import SlidingCarousel from "@components-v3/SlidingCarousel";
import { Button } from "@components-v3/Button";
import { ContentWrapper } from "@components-v3/ContentWrapper";
import styled from "styled-components";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";

interface ReviewsSectionProps {
  title: string;
  children: JSX.Element[];
  buttonUrl: string;
}

const CustomSection = styled(Section)`
  background-color: ${colors.babyBlue};
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 30px 0px;
  }
`;

const ReviewsSection = ({
  title,
  children,
  buttonUrl,
}: ReviewsSectionProps) => {
  return (
    <CustomSection
      style={{ display: "flex", placeContent: "center", alignItems: "center" }}
    >
      <ContentWrapper>
        <H2 style={{ fontFamily: "header bold", textAlign: "center" }}>
          {title}
        </H2>
        <SlidingCarousel>
          {children.map(c => (
            <div style={{ margin: "10px 5px", height: "100%" }}>{c}</div>
          ))}
        </SlidingCarousel>
        <div style={{ marginTop: "60px" }}>
          <Button url={buttonUrl} linkText={"See more clients"}></Button>
        </div>
      </ContentWrapper>
    </CustomSection>
  );
};

export { ReviewsSection };
export type { ReviewsSectionProps };
